import React from 'react';
import { Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Timeline = ({ timelineItems, lineColor = 'grey', color = 'grey' }) => {
  return (
    <VerticalTimeline>
      {timelineItems.map(({ dates, title, subtitle, summary, iconName }, i) => {
        return (
          <VerticalTimelineElement
            key={i}
            className='vertical-timeline-element--work'
            contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
            date={dates}
            iconStyle={{
              background: 'rgb(233, 30, 99)',
              color: '#fff',
              display: 'flex',
              'justify-content': 'center',
              'align-items': 'center',
            }}
            icon={<Icon size='large' name={iconName} />}
          >
            <h3 className='vertical-timeline-element-title'>{title}</h3>
            <h4 className='vertical-timeline-element-subtitle'>{subtitle}</h4>
            <p>{summary}</p>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};

export default Timeline;
