import React from 'react';
import { Jumbotron, Button } from 'reactstrap';

const onClick= () => {
  window.location.href="/portfolio";
}

const IndexHero = (props) => {
  return (
    <div>
      <Jumbotron className={props.class}>
        <h1 className="hero-title" dangerouslySetInnerHTML={{__html: props.title}}/>
        <Button color="info" size="lg" onClick={onClick}>My Work</Button>
      </Jumbotron>
    </div>
  );
};

export default IndexHero;