import React from 'react';
import MainLayout from 'src/layouts/Main';
import IndexHero from 'components/IndexHero';
import Timeline from 'components/Timeline';
import { graphql } from 'gatsby';
import PortfolioCard from 'components/PortfolioCard';

export default ({ data }) => {
  const pageContent = data.allPageContentJson.edges[0];
  const __ = data.allPortfolioJson.edges;

  const cards = (stacked = false) => {
    return __.map((_, i) => {
      return (
        <PortfolioCard
          stacked={stacked}
          key={i}
          imageURL={_.node.imageURL}
          title={_.node.title}
          description={_.node.description}
          repo={_.node.repo}
          liveSiteURL={_.node.liveSiteURL}
        />
      );
    });
  };

  return (
    <MainLayout>
      <IndexHero title={pageContent.node.headline} class='index-hero' />
      <div className='flex-content-container'>
        <div className='content-container col-one hidden-stack'>
          <div className='feature-text'>
            <p>
              The Journey So Far<span></span>
            </p>
          </div>
          <div>
            <Timeline
              timelineItems={[
                {
                  dates: 'August 2015',
                  title: 'Completed HS Requirements at the age of 16',
                },
                {
                  dates: 'May 2017',
                  title: 'Graduated High School as a Distinguished Scholar',
                },
                {
                  dates: 'May 2019',
                  title:
                    'Graduated with an Associates of Applied Science and a Music Technology Certificate',
                },

                {
                  dates: 'Sept 2019 - Nov 2019',
                  title: 'Worked at Amazon as Warehouse Associate',
                },

                {
                  dates: 'Jan 2020',
                  title: 'Started at Nashville Software School',
                },
                {
                  dates: 'June 2020',
                  title: 'Graduated from NSS',
                },
                {
                  dates: 'August 2020 - present',
                  title: 'Started at EventBrite',
                },
              ]}
            />
            <hr></hr>
          </div>
        </div>
        <div className='col-two hide-on-stack'>
          <div className='sidebar-header'>
            <p>
              Featured Work<span></span>
            </p>
          </div>
          <div className='level-9-margin-top flex-content-container portcards'>
            {cards(true)}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export const pageQuery = graphql`
  query {
    allPageContentJson(filter: { templateKey: { eq: "home" } }) {
      edges {
        node {
          headline
        }
      }
    }

    allPortfolioJson(limit: 2, sort: { fields: itemNumber }) {
      edges {
        node {
          itemNumber
          imageURL
          title
          description
          repo
          liveSiteURL
        }
      }
    }
  }
`;

// allTutorialsJson(limit:3) {
//     edges {
//         node {
//             title
//             date
//             body
//             description
//             fields {
//                 slug
//             }
//         }
//     }
// }
